import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-quill/dist/quill.snow.css";
import "./App.css";
import { Website } from "components/Website";
import { ErrorPage } from "components/ErrorPage";
import { AppWrapper } from "styles";

function App() {
  return (
    <AppWrapper>
      <Router>
        <Switch>
          <Route exact path="/:username/:projectSlug">
            <Website />
          </Route>

          {/* change to 404 */}
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </AppWrapper>
  );
}

export default App;
