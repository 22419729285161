import { Container } from "./styles";

export const ErrorPage = ({ error = 404 }) => {
  return (
    <Container>
      <h1>{error}</h1>
      <p>Page not found</p>
    </Container>
  );
};
