import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicEndpoints } from "structure/endpoints";
import { Content } from "./Content";
import { FullPageLoading } from "components/shared/Loading";

export const Website = () => {
  const { username, projectSlug } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [isNotFound, setIsNotFound] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      publicEndpoints
        .getPage(username, projectSlug)
        .then((res) => setData(res))
        .catch(() => setIsNotFound(true))
        .finally(() => setIsLoading(false));
    }, 1500);

    // eslint-disable-next-line
  }, []);

  if (isLoading) return <FullPageLoading />;

  if (isNotFound) return <p>Page not found</p>;

  if (!data) return <p>Unexpected error accured</p>;

  return <Content data={data} />;
};
