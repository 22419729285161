import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const Title = styled.h1`
  text-align: center;
  padding: 12px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BasicButton = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
  font-family: inherit;
  color: inherit;

  :hover {
    background: transparent;
    color: inherit;
    opacity: 0.8;
  }
`;

export const AsLink = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
  font-family: inherit;
  color: var(--secondary-500);
  font-size: 16px;
  padding: 0;
  text-decoration: underline;

  :hover {
    color: var(--secondary-700);
  }
`;

export const AsText = styled.button`
  padding: 0px;
  border: 0px;
  font-family: inherit;
  background: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 2px;

  &:hover {
    background: transparent;
    color: inherit;
    opacity: 0.8;

    svg {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      opacity: 0.5;

      svg {
        opacity: 1;
      }
    }
  }

  svg {
    height: 1.4em;
    width: 1.4em;
  }
`;

export const ButtonWithIcon = styled(Button)`
  &.ui.button {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;

    &.ui.button {
      padding-left: 10px;
    }
  }
`;

export const scrollElement = `
::-webkit-scrollbar {
            width: 24px; 
            border-radius: 16px;
          }
          
          ::-webkit-scrollbar-track {
            background: var(--grey-300);
            border: 8px solid transparent; 
            background-clip: content-box;
            border-radius: 16px;
          }
          
         ::-webkit-scrollbar-thumb {
            background: var(--grey-500);
            border-radius: 8px; 
            border: 8px solid transparent; 
            background-clip: content-box;
            border-radius: 16px;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: var(--grey-700);
            border-radius: 8px; 
            border: 8px solid transparent; 
            background-clip: content-box;
            border-radius: 16px;
          }
          `;

export const CaruselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background: white;
  padding: 16px 16px 8px;
  border-radius: 4px;

  .alice-carousel__dots-item:not(.__custom) {
    height: 12px;
    width: 12px;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .alice-carousel__prev-btn-wrapper,
  .alice-carousel__next-btn-wrapper {
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    padding: 0;
    height: 30px;
    width: 30px;
    cursor: pointer;

    position: absolute;
    top: calc(50% - 28px);

    border-radius: 4px;
    background-color: #6e7ebc;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    z-index: 1;
  }

  .alice-carousel__dots {
    margin-top: 8px;
  }

  .alice-carousel__prev-btn {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="18" height="18" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><title>Shape</title><path d="M33.6 14.7H8.043L19.782 2.961 16.8 0 0 16.8l16.8 16.8 2.961-2.961L8.043 18.9H33.6z" fill="%23FFF" fill-rule="evenodd"/></svg>');
    left: -10px;
  }

  .alice-carousel__next-btn {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="18" height="18" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><title>Shape</title><path d="M25.557 14.7L13.818 2.961 16.8 0l16.8 16.8-16.8 16.8-2.961-2.961L25.557 18.9H0v-4.2z" fill="%23FFF" fill-rule="evenodd"/></svg>');
    right: -10px;
  }

  .alice-carousel__next-btn [data-area]::after,
  .alice-carousel__prev-btn [data-area]::after {
    content: "";
  }

  .alice-carousel__prev-btn-wrapper,
  .alice-carousel__next-btn-wrapper,
  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    width: 100%;
    height: 100%;

    &.__inactive {
      background: white;
      border-radius: 4px;
      opacity: 0.7;
    }
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .ui.input input:focus {
    background: var(--grey-50);
  }

  .ui.error {
    input {
      border: 1px solid var(--red-200);
      background: var(--red-50);
    }
  }

  .ls-error {
    color: var(--toastify-color-error);
  }
`;

export const FitContentWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const GalleryItem = styled(FitContentWrapper)`
  padding: 8px;
  img {
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  > div {
    background: black;
    max-width: 100%;
    border-radius: 16px;
    overflow: hidden;
  }
`;

export const GeneralEdit = styled.div`
  h4 {
    font-size: 24px;
    color: var(--grey-800);
    line-height: 1.2;
    margin-bottom: 4px;
  }

  p {
    color: var(--grey-600);
  }

  form,
  .ls-main-block {
    .ls-actions {
      padding: 0;
      > .button {
        margin: 0;
      }
    }
  }
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  > textarea {
    margin: 0;
    width: 100%;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    resize: none;
  }
`;

export const TopMenu = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 40px;
  border-bottom: 1px solid var(--grey-200);
  height: 56px;
  position: sticky;
  top: 0px;
  background: var(--white);
  z-index: 1;

  .nav {
    .ui.button {
      padding: 8px;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-inline-start: 0;
    list-style-type: none;
    margin: 0;
  }

  .ui.button.ls-icon {
    border: 1px solid transparent;
    fill: var(--grey-700);
    transition: border-color 0.1s;

    :hover {
      border-color: var(--grey-300);
    }
  }

  + .main-section {
    padding-top: 32px;
  }
`;
