import { Input } from "semantic-ui-react";
import { Field } from "react-final-form";
import { FormField } from "./common-styles";

export const FieldText = ({ label, finalFormProps, semanticProps }) => {
  return (
    <Field {...finalFormProps}>
      {({ meta, input }) => {
        const hasError =
          meta.touched &&
          (meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit));

        return (
          <FormField>
            {typeof label === "string" ? <label>{label}</label> : label}
            <Input
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              error={hasError}
              {...semanticProps}
            />
            {hasError && (
              <p className="ls-error">{meta.error || meta.submitError}</p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
