import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: rgb(252 245 234);
`;

export const AppWrapper = styled.div`
  .ui.floating.dropdown > .menu {
    border-radius: 8px !important;
    box-shadow: var(--shadow-400) !important;
    border: 1px solid var(--grey-200);
    overflow: hidden;

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      color: var(--grey-700);
      fill: var(--grey-700);
      text-transform: capitalize;

      :hover {
        background: var(--grey-100);
        color: var(--grey-800);
        fill: var(--grey-800);
      }
    }
  }
`;
